import React from 'react';
import Layout from '../components/Layout';
import Heading from '../components/Heading';
import ContactPage from '../components/ContactPage'


const Contact = () => {
    return (
        <Layout>
                <Heading align="center">Contact Us</Heading>
                <ContactPage />
        </Layout> 
    )
};

export default Contact;