import React from 'react';
import './ContactPage.css'


const ContactPage = () => {
    return (
                <>
                <div className="contaxt-box">
                    {/* <div className="contact-address">
                        <address>
                        218 Elkwood Ave, Suite 104<br></br>
                        Woodfin, NC 28804
                        </address>
                    </div> */}
                    <div className="contact-hours">
                        <h3>Office Hours: </h3>
                        {/* <p>Monday: 9:00 AM–5:00 PM</p>
                        <p>Tuesday: 9:00 AM–5:00 PM</p>
                        <p>Wednesday: 9:00 AM–5:00 PM</p>
                        <p>Thursday: 9:00 AM–5:00 PM</p>
                        <p>Friday: 9:00 AM–5:00 PM</p>
                        <p>Saturday: Closed</p>
                        <p>Sunday: Closed</p> */}
                        <h4>Monday - Friday: 9:00 AM–5:00 PM</h4>
                        <h4>Saturday and Sunday: Closed</h4>
                    </div>
                </div>
                {/* <div className="contact-email">
                        <p>
                        E-mail our office:
                        <a href="mailto:office@dermatologyasheville.com?Subject=Patient%20Question" target="_top"> E-mail Link</a>
                        </p>
                    </div> */}
                <div>
                    <div className="contact-iframe-contain">
                    <iframe className="contact-iframe" title="Appointment Request Form" src="https://docs.google.com/forms/d/e/1FAIpQLSdgHt_ea8Cswvfq8xm5Gk0nPvJzpv4xDL1hVqGT_cEmpPVLYw/viewform?embedded=true" width="640" height="844" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    </div>
                {/* <iframe className="contact-iframe" src="https://docs.google.com/forms/d/e/1FAIpQLSdgHt_ea8Cswvfq8xm5Gk0nPvJzpv4xDL1hVqGT_cEmpPVLYw/viewform?embedded=true" width="640" height="844" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
                </div>
                </>
 
    )
};

export default ContactPage;

